* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.grabbable {
  margin-top: 15px !important;
}

/* Container needed to position the overlay. Adjust the width as needed */
.pfp-container {
  position: relative;
  width: 100%;
  /* max-width: 400px; */
}


/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .4s ease;
  background-color: rgba(0,0,0,0);
  color: #fff;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

/* When you mouse over the container, fade in the overlay icon*/
.pfp-container:hover .overlay {
  background-color: rgba(0,0,0,0.5);
  opacity: 1;
}

.underlined-link:hover {
  text-decoration: none;
}

.view-links {
  position: relative;
  background-image: url("https://res.cloudinary.com/ana3d/image/upload/v1675192527/profilepictures/63af3a6cea6904c9874f253d.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  filter: blur(8px); /* Adding blur background image */
  -webkit-filter: blur(8px); /* Safari 6.0 - 9.0 */
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  z-index: -1;
 

}

.view-links-img {
  position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      width: 100%;
      height: 100%;
      -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
      filter: blur(5px);
}